/* eslint no-console:0 */

import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/modal';

import '@/stylesheets/main.scss';

import expandButton from '@/utilities/expand-button';

document.addEventListener('DOMContentLoaded', () => {
  expandButton();
});


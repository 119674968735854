export default function expandButton() {
  const btn = document.querySelector('.expand-btn');
  if (btn === null) return;

  btn.addEventListener('click', function(e) {
    e.preventDefault();

    let elemToOpen = this.getAttribute('href');

    if (elemToOpen === undefined) { return; }

    document.querySelector(elemToOpen).classList.toggle('hide-in-default');
  });
}
